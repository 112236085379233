<template>
  <div class="layout">
    <el-container>
      <!-- 头部 -->
      <el-header height="65px">
        <div style="width: 100%; height: 65px; line-height: 65px; background: #fff">
          <!-- <img src="../../src/assets/logo1.png" class="top_logo" alt="" srcset="" /> -->
          <h1 style="color: dodgerblue;float: left;"> &nbsp; 铃铛租</h1>

          <div class="container_login">
            <img src="../assets/approve.png" alt="" srcset="" />
            <el-dropdown>
              <span class="el-dropdown-link" style="color: #ccc">
                {{account}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="clearadd()">退出</el-dropdown-item>
                <!-- <el-dropdown-item>分享链接</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-container>
        <!-- 左边菜单栏 -->
        <el-aside width="16em" style="height: calc(100vh - 70px); background: #fff; border-right: solid 1px #e6e6e6">
			
          <el-menu>
            <el-submenu v-for="(item,indid) in dataA" :key="indid" :index="indid + ''">
              <template slot="title"><i :class="item.menu_icon"></i>{{item.menu_name}}</template>
              <el-menu-item v-for="(itemA,indexA) in item.children" :key="indexA" :index="itemA.path + '' " @click="goTo(itemA.path)">{{itemA.menu_name}}</el-menu-item>
            </el-submenu>
            
           
            <el-menu-item index="/icon" @click="goTo('/icon')">icon</el-menu-item>
          </el-menu>
        </el-aside>

        <!-- main -->
        <el-main>
          <!-- <div class="bread_top">
									<el-breadcrumb >
									            <el-breadcrumb-item
												v-for="item in breadList"
									            :key="item.path"
									            :to="item.path"
									            >{{ item.name }}</el-breadcrumb-item>
									</el-breadcrumb>
								</div> -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import bus from '@/utils/bus'
export default {
  name: 'layout',
  data() {
    return {
      breadList: [],
		dataA:[],
		account:''
    }
  },

  created() {

  },
 
  mounted(){
	// let  _this = this
	// //console.log(this.$store.state)
	this.dataA = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("vueX"))))
	this.account = this.$cookies.get("account")
  },
  methods: {
    clearadd() {
      localStorage.clear()
	  this.$cookies.remove("role_id")
	  this.$cookies.remove("merchant_id")
	  this.$cookies.remove("yh_id")
	  this.$cookies.remove("account")
	  this.$cookies.remove("token")
      this.$router.replace('/login')
    },

    goTo(path) {
      //console.log(path)
      this.$router.replace(path)

    },
    
  },
}
</script>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
}

.container_login {
  float: right;
  text-align: left;
  color: #fff;
  position: relative;
  left: -20px;
}

.container_login img {
  width: 40px;
  height: 40px;
  position: relative;
  top: 15px;
  left: -10px;
}

.top_logo {
  width: 175px;
  position: relative;
  top: 9px;
  left: 20px;
}
.el-header {
  padding: 0;
}

.bread_top {
  width: 100%;
  height: 40px;
  background: #fff;
  position: relative;
  top: -15px;
}

.el-breadcrumb {
  line-height: 40px;
}

.el-menu {
  border-right: solid 0px #e6e6e6;
  background: #fff;
}

.el-col-12 {
  width: 15%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  color: #fff;
  background-color: #2b80ff;
}

.el-submenu__title:hover {
  color: #fff;
  background-color: #2b80ff;
}
</style>
